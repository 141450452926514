import { AccountingImpact, CashFlowWalletGroupBy } from '@legalplace/shared';

export const dashboard = {
  page_title: 'Tableau de bord',
  unexpected_error: 'Une erreur est survenue.',
  last_twelve_months: 'Les 12 derniers mois',
  current_year: 'Année en cours',
  year: 'Année',
  main_expenses: {
    total_balance: 'Principales dépenses',
    unlockable_banner_content: {
      title: 'Débloquez ces informations !',
      message: `Identifiez simplement vos dépenses pour améliorer vos performances financières grâce à la catégorisation automatique.`,
      button_label: `Découvrir l'offre comptabilité`,
      unexpected_error: 'Une erreur est survenue.',
    },
    unlock_in_progress_banner_content: {
      title: `En cours d'activation`,
      message: `Vos informations seront mises à jour très prochainement.`,
      unexpected_error: 'Une erreur est survenue.',
    },
  },
  wallet_cash_flow: {
    title: 'Canaux de ventes',
    [CashFlowWalletGroupBy.PaymentMethod]: 'Moyens de paiements',
    [CashFlowWalletGroupBy.SalesChannel]: 'Canaux de ventes',
  },
  charts_filter: {
    accounting_period: 'Exercice {{accountingPeriod}}',
    current_accounting_period: 'Exercice en cours',
    charts_title: 'Graphiques',
    date_filter_button: 'Date',
    date_filter_action_button: 'Appliquer',
    date_reset_action_button: 'Réinitialiser',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    mobile_filter_button: 'Date',
    title: 'Filtres',
    date_filter_title: 'Date',
    reset_button: 'Réinitialiser',
    reset_all_button: 'Réinitialiser',
    apply_filters_button: 'Appliquer',
  },
  financial_performance: {
    by_month: {
      title: 'Performance financière (HT)',
      turnover: "Chiffre d'affaires (1)",
      operating_costs: 'Charges (2)',
      not_categorized_cash_flow: 'Non catégorisé',
      operating_income: 'Résultat (1-2)',
      tooltip: {
        message:
          "Ce graphique détaille le résultat d'exploitation de l'entreprise mois par mois.",
        help_button_label: 'En savoir plus',
      },
      unlockable_banner_content: {
        title: 'Débloquez ces informations !',
        message: `Pilotez la performance financière de votre société et bénéficiez des conseils de votre comptable.`,
        button_label: `Découvrir l'offre comptabilité`,
        unexpected_error: 'Une erreur est survenue.',
      },
      unlock_in_progress_banner_content: {
        title: `En cours d'activation`,
        message: `Vos informations seront mises à jour très prochainement.`,
        unexpected_error: 'Une erreur est survenue.',
      },
    },
    by_accounting_period: {
      title: 'Récapitulatif de l’exercice (HT)',
      turnover: 'Chiffre d’affaires (1)',
      operating_costs: 'Charges (2)',
      operating_income: 'Résultat (1-2)',
      unlockable_banner_content: {
        title: 'Débloquez ces informations !',
        message: `Pilotez la performance financière de votre société et bénéficiez des conseils de votre comptable.`,
        button_label: `Découvrir l'offre comptabilité`,
        unexpected_error: 'Une erreur est survenue.',
      },
      unlock_in_progress_banner_content: {
        title: `En cours d'activation`,
        message: `Vos informations seront mises à jour très prochainement.`,
        unexpected_error: 'Une erreur est survenue.',
      },
      tooltip: {
        [AccountingImpact.Turnover]: 'Chiffre d’affaires',
        [AccountingImpact.Charges]: 'Charges',
        [AccountingImpact.ProduitExceptionnel]: 'Produits exceptionnels',
        [AccountingImpact.ProduitFinancier]: 'Produits financiers',
        [AccountingImpact.SubventionExploitation]: 'Subventions d’exploitation',
        [AccountingImpact.AmortissementsSurImmobilisations]: 'Amortissements',
        [AccountingImpact.RepriseSurAmortissements]:
          'Reprises sur amortissements',
        [AccountingImpact.ChargesFinancieres]: 'Charges financières',
        [AccountingImpact.Impots]: 'Impôts',
        [AccountingImpact.ProductionStockeImmobilise]: 'Stock immobilisé',
        [AccountingImpact.ChargesExceptionnelles]: 'Charges exceptionnelles',
        totalResult: 'Résultat',
      },
      [AccountingImpact.Turnover]: 'Chiffre d’affaires ({{index}})',
      [AccountingImpact.Charges]: 'Charges ({{index}})',
      [AccountingImpact.ProduitExceptionnel]:
        'Produits exceptionnels ({{index}})',
      [AccountingImpact.ProduitFinancier]: 'Produits financiers ({{index}})',
      [AccountingImpact.SubventionExploitation]:
        'Subventions d’exploitation ({{index}})',
      [AccountingImpact.AmortissementsSurImmobilisations]:
        'Amortissements ({{index}})',
      [AccountingImpact.RepriseSurAmortissements]:
        'Reprises sur amortissements ({{index}})',
      [AccountingImpact.ChargesFinancieres]: 'Charges financières ({{index}})',
      [AccountingImpact.Impots]: 'Impôts ({{index}})',
      [AccountingImpact.ProductionStockeImmobilise]:
        'Stock immobilisé ({{index}})',
      [AccountingImpact.ChargesExceptionnelles]:
        'Charges exceptionnelles ({{index}})',
      totalResult: 'Résultat ({{index}})',
    },
  },
  balance_history: {
    title: 'Suivi du solde',
  },
  cash_flow: {
    title: 'Mouvement de trésorerie',
    cash_in_flow: 'Entrées',
    cash_out_flow: 'Sorties',
    balance: 'Balance',
  },
  bank_balance: {
    pending_message: '{{pendingBalance}} à venir',
    total_balance: 'Solde total',
    manage_accounts: 'Gérer mes comptes',
    pending_balance: 'À venir : {{pendingBalance}}',
    banners: {
      unsynced_accounts: {
        title: 'Compte désynchronisé',
        message:
          'Synchronisez votre compte bancaire professionnel pour voir vos données.',
        button_text: 'Synchroniser votre compte',
      },
    },
    tooltip: {
      message:
        'Ceci correspond au solde qui sera prélevé prochainement sur votre compte professionnel.',
    },
    main_account: 'Compte principal',
  },
  vat_balance: {
    total_balance: 'Balance de TVA',
    banners: {
      approximative_data: {
        title: 'Données approximatives',
        message:
          'Actuellement votre balance de TVA est majorée. Catégorisez toutes vos transactions pour connaître votre solde de TVA exact.',
        faq_link_label: 'Comprendre ma balance de TVA',
      },
    },
    tooltip: {
      message:
        'La balance de TVA est composée de la TVA collectée et de la TVA déductible.',
      help_button_label: 'En savoir plus',
    },
    vat_label: 'TVA',
    balance_at_start_vat_label: 'TVA (depuis le {{vatReferenceDate}})',
    amount_label: 'Montant',
    collected_vat_label: 'Collectée',
    deductable_vat_label: 'Déductible',
    payment_vat_label: `Payée à l'état`,
    credited_vat_label: `Remboursée par l'état`,
    balance_vat_label: 'Solde au {{vatReferenceDate}}',
    unlockable_banner_content: {
      title: 'Débloquez ces informations !',
      message: `Votre TVA calculée automatiquement, il ne vous reste plus qu'à la déclarer simplement depuis votre logiciel.`,
      button_label: `Découvrir l'offre comptabilité`,
      unexpected_error: 'Une erreur est survenue.',
    },
    unlock_in_progress_banner_content: {
      title: `En cours d'activation`,
      message: `Vos informations seront mises à jour très prochainement.`,
      button_label: `Comprendre ma balance de TVA`,
      unexpected_error: 'Une erreur est survenue.',
    },
    owed_to_state: "Dû à l'État",
    creditor_balance: "Dû par l'État",
    unknown_error: 'Une erreur est survenue, veuillez réessayer.',
    export_success: 'Téléchargement démarré !',
  },
  pca_balance: {
    total_balance: `Compte courant d'associés`,
    pca_show_details: `Voir les comptes d'associés`,
    pca_label: `Associé`,
    amount_label: 'Montant',
    tooltip: {
      message:
        "Le compte courant d'associé est un prêt consenti par un associé, un dirigeant ou un salarié à la société.",
      help_button_label: 'En savoir plus',
    },
    unlockable_banner_content: {
      title: 'Débloquez ces informations !',
      message: `Souscrivez l’offre de comptabilité LegalPlace Pro pour simplifier votre gestion des comptes courants d'associés.`,
      button_label: `Découvrir l'offre comptabilité`,
      unexpected_error: 'Une erreur est survenue.',
    },
    unlock_in_progress_banner_content: {
      title: `En cours d'activation`,
      message: `Vos informations seront mises à jour très prochainement.`,
      button_label: `Comprendre mon compte courant d'associés`,
      unexpected_error: 'Une erreur est survenue.',
    },
    unexpected_error: 'Une erreur est survenue.',
  },
  no_accounts_banner: {
    title: "Débloquez l'ensemble des fonctionnalités de l'application",
    subtitle:
      'Impossible pour LegalPlace Pro de gérer votre comptabilité sans compte connecté !',
    add_account_button_label: 'Connecter mon compte',
  },
  transaction_type_breakdown: {
    title: 'Type de transactions',
  },
  accounting_upsell_modal: {
    offer_modal_title: `Facilitez-vous la vie avec l'offre comptabilité !`,
    upsell_success_modal_title: "C'est parti ! Merci pour votre confiance !",
    offer_step: {
      accounting_feature_1: 'Conseils illimités avec votre comptable',
      accounting_feature_2:
        'Bilan, déclarations de TVA, liasse fiscale et dépôts aux impôts gérés par votre comptable',
      accounting_feature_3:
        'Pilotage complet de votre compta sur LegalPlace Pro : gestion des notes de frais, catégorisation automatique, balance de TVA, tableaux de bords',
      accounting_feature_mobile_2: `Logiciel simple et sans saisie manuelle grâce à l'intelligence artificielle`,
      accounting_feature_mobile_3: `Comptables réactifs par chat`,
      accounting_feature_mobile_4: `Bilan, déclaration de TVA, émission de liasse fiscale, dépôt aux impôts réalisés par nos soins.`,
      optin_button_label: 'Commencer mon essai gratuit',
      optin_information_label:
        '30 jours gratuit puis à partir de 85 € HT/mois. Désabonnement gratuit à tout moment.',
      sci_optin_information_label:
        '30 jours gratuit puis à partir de 65 € HT/mois. Désabonnement gratuit à tout moment.',
      more_info_button_label: `Plus de détails sur l'offre comptabilité`,
    },
    calendly_step: {
      unexpected_error: 'Une erreur est survenue.',
    },
  },
};
