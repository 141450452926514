import { CategorizableItemType } from '@legalplace/shared';

import { VatValueOptions } from '../../constants';
import {
  PERSONAL_EXPENSE_CATEGORY_BANNER_MESSAGE,
  SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE,
  TRANSPORTATION_EXPENSE_CATEGORY_BANNER_MESSAGE,
  VEHICLE_VAT_CATEGORY_BANNER_MESSAGE,
} from '../../constants/categoriesMessage.constants';

export const accountingOverlay = {
  ToCategorize_accounting_label: 'À catégoriser',
  ToJustify_accounting_label: 'Justificatif manquant',
  Categorized_accounting_label: 'Categorisé',
  inconsistent: 'Incohérent',
  category_button_label: 'Choisir',
  amount: 'Montant TTC',
  category: 'Catégorie',
  categorized_by_ai: 'Catégorisation automatique par IA',
  undefined_category: 'Non renseignée',
  proofs_title: 'Justificatifs',
  upload_file: 'Ajouter un fichier',
  max_file_uploaded_message:
    'Vous avez atteint le nombre maximum de justificatifs autorisés par transaction.',
  allowed_types: 'PDF, PNG, JPEG',
  confirm_delete: 'Oui, supprimer',
  cancel_delete: 'Annuler',
  download_started: 'Téléchargement démarré !',
  operation_title: 'Opérations ({{operationCount}})',
  save_amount: 'Valider',
  rebalance: 'Equilibrer',
  unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
  immobilisation_label: 'Immobilisation',
  immobilisation_checked: 'Immobilisé',
  immobilisation_unchecked: 'Non immobilisé',
  tooltip: {
    immobilisation_message:
      "Une immobilisation est une dépense de l'entreprise destinée à être utilisée sur plusieurs exercices.",
    immobilisation_help_button_label: 'En savoir plus',
  },
  category_banner_message: {
    D36: "La TVA n'est pas récupérable pour les dépenses de type train, taxi, bus, avion, VTC.",
    D37: "La TVA n'est pas récupérable pour les dépenses de type Hôtels & Hébergement. Elle est uniquement récupérable sur les éventuels frais de repas que vous avez pu avoir au cours de votre déplacement.",
    D18: PERSONAL_EXPENSE_CATEGORY_BANNER_MESSAGE,
    D31: PERSONAL_EXPENSE_CATEGORY_BANNER_MESSAGE,
    D125: "Cette dépense impacte votre compte courant d'associé.",
    D20: TRANSPORTATION_EXPENSE_CATEGORY_BANNER_MESSAGE,
    D33: TRANSPORTATION_EXPENSE_CATEGORY_BANNER_MESSAGE,
    C23: SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE,
    C26: SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE,
    C14: "Cet encaissement d'espèce est considéré comme un apport de l'associé à la société, et impact le compte courant d'associé.",
    C27: SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE,
    C24: SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE,
    C25: SALES_CHANNEL_CATEGORIES_BANNER_MESSAGE,
    D32: VEHICLE_VAT_CATEGORY_BANNER_MESSAGE,
    D19: VEHICLE_VAT_CATEGORY_BANNER_MESSAGE,
    D137: "Ce retrait d'espèce est considéré comme un transfert d'argent de la société vers l'associé, et impacte le compte courant d'associé.",
    D58: "Ce versement de dividendes impacte le compte courant d'associé.",
    D113: "Vous pourrez diviser ce montant entre le remboursement du capital emprunté, les intérêts d'emprunt et l'assurance de l'emprunt au moment de vos comptes de fin d'exercice.",
    D138: "Cette note de frais correspond aux dividendes votés lors de l'Assemblée Générale Ordinaire Annuelle (AGOA), moins les impôts (flat tax ou taux personnalisé) dûs sur ces dividendes.",
  },
  file_preview_title: 'Justificatif - {{fileName}}',
  vat_label: 'TVA',
  geography_label: 'Géographie',
  geography_label_FRA: 'France',
  geography_label_FRMET: 'France métropolitaine',
  geography_label_DOMTOM: 'DOM-TOM',
  geography_label_CORSE: 'Corse',
  geography_label_UE: 'Union Européenne',
  geography_label_AUTRE: 'Hors UE',
  geography_label_FRAAUTOLIQUIDE: 'France - Autoliquidée',
  geography_label_UNDEFINED: 'Choisir la localisation',
  purchase: 'Note de frais (achats divers)',
  mileage_allowance: 'Note de frais (indemnité kilométrique)',
  milleage_allowance_description: 'Véhicule : {{vehicleLabel}}',
  edit_expense_button: 'Modifier',
  duplicate_expense_button: 'Dupliquer',
  delete_expense_button: 'Supprimer',
  expense_report_deleted: 'Note de frais supprimée !',
  geography_dropdown_placeholder: 'Choisir la localisation',
  transaction_types: {
    Carte: 'Paiement par carte',
    CarteMagasin: 'Paiement par carte (en magasin)',
    CarteEnLigne: 'Paiement par carte (sur internet)',
    RetraitDistributeur: 'Retrait au distributeur',
    Virement: 'Virement',
    VirementInstantané: 'Virement instantané',
    Prélèvement: 'Prélèvement',
    NoteDeFrais: {
      purchase: 'Note de frais (achats divers)',
      mileage_allowance: 'Note de frais (indemnité {{distance}} km)',
    },
  },
  category_modal_drawer: {
    save_ci: 'Enregistrer',
    add_ci: 'Ajouter',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    add_ci_placeholder: {
      [CategorizableItemType.CREDIT_BAIL]: 'Nom du crédit-bail',
      [CategorizableItemType.VEHICULE_PRO]: 'Nom du véhicule',
      [CategorizableItemType.ASSURANCE]: "Nom de l'assurance",
      [CategorizableItemType.EMPRUNT]: "Nom de l'emprunt",
      [CategorizableItemType.FILIALE]: 'Nom de la filiale',
    },
    add_ci_label: {
      [CategorizableItemType.CREDIT_BAIL]: 'Nouveau crédit-bail',
      [CategorizableItemType.VEHICULE_PRO]: 'Nouveau véhicule professionnel',
      [CategorizableItemType.ASSURANCE]: 'Nouvelle assurance',
      [CategorizableItemType.EMPRUNT]: 'Nouvel emprunt',
      [CategorizableItemType.FILIALE]: 'Nouvelle filiale',
      [CategorizableItemType.METHODE_DE_PAIEMENT]: 'Nouveau canal de vente',
    },
    empty_category_search_message:
      'Aucune catégorie ne correspond à votre recherche.',
  },
  similar_transaction_modal_drawer: {
    title_one: '1 transaction similaire identifiée',
    title_other: '{{count}} transactions similaires identifiées',
    primary_button_label: 'Appliquer la même catégorie',
    secondary_button_label: 'Ne pas appliquer',
    message:
      'Sélectionnez les transactions pour lesquelles appliquer la même catégorie.',
    select_all_button_label: 'Tout sélectionner',
    unselect_all_button_label: 'Tout désélectionner',
    vat_dropdown: {
      [VatValueOptions.VAT_0_FRA]: 'TVA 0 % - France',
      [VatValueOptions.VAT_0_UE]: 'TVA 0 % - UE',
      [VatValueOptions.VAT_0_OTHER]: 'TVA 0 % - Hors UE',
      [VatValueOptions.VAT_0_FRAAUTOLIQUIDE]: 'TVA 0 % - France - Autoliquidée',
      [VatValueOptions.VAT_210]: 'TVA 2,1 %',
      [VatValueOptions.VAT_550]: 'TVA 5,5 %',
      [VatValueOptions.VAT_850]: 'TVA 8,5 %',
      [VatValueOptions.VAT_1000]: 'TVA 10 %',
      [VatValueOptions.VAT_2000]: 'TVA 20 %',
      [VatValueOptions.VAT_UNDEFINED]: 'TVA',
    },
  },
  add_proof_modal: {
    modal_title: 'Ajouter un fichier',
    import_file: {
      tab_label: 'Importer',
      upload_file: 'Glisser ou <u>sélectionner un justificatif</u>',
      allowed_types: 'PDF, PNG, JPEG',
      confirm_delete: 'Oui, supprimer',
      cancel_delete: 'Annuler',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    },
    associate_invoice: {
      tab_label: 'Associer une de mes factures',
      searchbar_placeholder: 'Nom, client, montant...',
      reset_button: 'Réinitialiser',
      invoices_search_button: 'Recherche',
      title: 'Filtres',
      bill_status: {
        Draft: 'Brouillon',
        Paid: 'Payée',
        Unpaid: 'À encaisser',
        Late: 'En retard',
        Cancelled: 'Annulée',
      },
      Draft: 'Brouillon',
      Paid: 'Payée',
      Unpaid: 'À encaisser',
      Late: 'En retard',
      Cancelled: 'Annulée',
      date_filter_button: 'Date',
      bill_status_filter_button: 'Statut',
      bill_status_reset_action_button: 'Réinitialiser',
      bill_status_filter_action_button: 'Appliquer',
      date_from: 'Date de début',
      date_to: 'Date de fin',
      date_reset_action_button: 'Réinitialiser',
      date_filter_action_button: 'Appliquer',
      date_filter_title: 'Date',
      billingInvoiceStatuses_filter_title: 'Statut',
      apply_filters_button: 'Appliquer',
      reset_all_button: 'Réinitialiser',
      mobile_filter_button: 'Filtrer',
      modal_title: 'Facture - {{fileName}}',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      download_started: 'Téléchargement démarré !',
      download_button: 'Télécharger',
    },
    close_button_label: 'Terminer',
    cancel_button_label: 'Annuler',
    unexpected_error: 'Une erreur est survenue.',
  },
};
