import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  SubscriptionFeatureStatus,
  V2_COMPANY_SETUP_STATUS,
} from '@legalplace/shared';
import {
  Button,
  FlexAlignCenterJustifySpaceBetween,
  Progress,
  Separator,
  StreamLineIcon,
  StreamLineIconList,
  Typography,
} from '@legalplace/storybook';

import { useAppSelector } from '../../hooks/store';
import { useCurrentDeviceBreakpoint } from '../../hooks/useCurrentDeviceBreakpoint';
import { getDaysBetweenDateAndNow } from '../../services/utils';
import { selectActiveCompany } from '../../store/companies/selectors';
import { selectOnboardingSteps } from '../../store/onboardingSteps/selector';

import { AccountingTrialEndModal } from './AccountingTrialEndModal';
import { MobileMenuOnboardingStepper } from './MobileMenuOnboardingStepper';

import { NowrapTypography } from '../UI/NowrapTypography.style';
import { UnderlinedTypography } from '../UI/UnderlinedTypography.style';
import {
  MainSubMenuOnboardingStepperButton,
  MenuOnboardingStepperContainer,
  SubMenuOnboardingStepperContainer,
} from './MenuOnboardingStepper.style';

export function MenuOnboardingStepper(): JSX.Element | null {
  const { t } = useTranslation('onboarding', {
    keyPrefix: 'onboarding_v2',
  });

  const { isMobileOrTablet, isSmallDesktop } = useCurrentDeviceBreakpoint();

  const shouldShowMobileVersion = isMobileOrTablet || isSmallDesktop;
  const navigate = useNavigate();
  const activeCompany = useAppSelector(selectActiveCompany);
  const activeCompanyId = activeCompany?.id;
  const goToOnboardingPage = (): void => {
    navigate(`${activeCompanyId}/onboarding-v2`, { replace: true });
  };
  const [isAccountingTrialEndModalOpen, setIsAccountingTrialEndModalOpen] =
    useState(false);

  const {
    progressPercentage,
    stepsLeftCount,
    currentStep,
    accountingOnboardingInformation,
  } = useAppSelector(selectOnboardingSteps);

  const isOnboardingComplete = stepsLeftCount === 0;

  if (
    !activeCompany?.setupStatus ||
    !V2_COMPANY_SETUP_STATUS.includes(activeCompany.setupStatus) ||
    !stepsLeftCount ||
    !progressPercentage ||
    isOnboardingComplete ||
    !currentStep
  ) {
    return null;
  }

  if (shouldShowMobileVersion) {
    return <MobileMenuOnboardingStepper />;
  }

  const remainingTrialDays = getDaysBetweenDateAndNow(
    activeCompany?.featureMap?.ComptaBase?.trialEndDate
  );

  const shouldDisplayNotInterestedButton =
    activeCompany?.featureMap?.ComptaBase?.status ===
      SubscriptionFeatureStatus.TrialExpired &&
    !accountingOnboardingInformation?.isContractSigned &&
    activeCompany?.featureMap?.BankBase?.isActive;

  const shouldDisplayTrialDays =
    !accountingOnboardingInformation?.isContractSigned &&
    remainingTrialDays !== undefined;

  return (
    <MenuOnboardingStepperContainer hasBorderRadius>
      <MainSubMenuOnboardingStepperButton onClick={goToOnboardingPage}>
        <FlexAlignCenterJustifySpaceBetween>
          <Typography tag="p4" fontWeight="600">
            {t('onboarding_stepper.finalize_registration_button_label')}
          </Typography>
          <StreamLineIcon icon={StreamLineIconList.arrowright1} />
        </FlexAlignCenterJustifySpaceBetween>
        <Typography tag="p4" spacing={{ default: '0 0 1.2rem 0' }}>
          {t('onboarding_stepper.nb_steps_left')}
        </Typography>
        <FlexAlignCenterJustifySpaceBetween gap={{ default: '1.2rem' }}>
          <Progress
            value={progressPercentage}
            color="pink200"
            progressBarHeight={{ default: '0.8rem' }}
            backgroundColor="white"
            borderRadius="2rem"
          />
          <NowrapTypography tag="p4">{`${progressPercentage}%`}</NowrapTypography>
        </FlexAlignCenterJustifySpaceBetween>
      </MainSubMenuOnboardingStepperButton>
      {shouldDisplayTrialDays && (
        <>
          <Separator backgroundColor="greytainted600" />
          <SubMenuOnboardingStepperContainer>
            <Typography tag="p4">
              {t('onboarding_stepper.remaining_trial_days', {
                remainingTrialDays,
              })}
            </Typography>
          </SubMenuOnboardingStepperContainer>
        </>
      )}
      {shouldDisplayNotInterestedButton && (
        <>
          <Separator backgroundColor="greytainted600" />
          <SubMenuOnboardingStepperContainer>
            <Button
              styleType="tertiary"
              onClick={() => setIsAccountingTrialEndModalOpen(true)}
            >
              <UnderlinedTypography tag="p4" color="white">
                {t('onboarding_stepper.not_interested_button_label')}
              </UnderlinedTypography>
            </Button>
          </SubMenuOnboardingStepperContainer>
        </>
      )}
      <AccountingTrialEndModal
        isOpen={isAccountingTrialEndModalOpen}
        setIsOpen={setIsAccountingTrialEndModalOpen}
        activeCompanyId={activeCompany?.id}
      />
    </MenuOnboardingStepperContainer>
  );
}
