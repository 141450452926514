import type { Event as ErrorEvent, EventHint } from '@sentry/browser';

import { EventError } from './EventError/EventError';
import { NonObjectError } from './NonObjectError/NonObjectError';

export function isErrorEvent(e: unknown): e is Error {
  return !!e && typeof e === 'object' && 'message' in e && 'stack' in e;
}

export function recomputeErrorAndEvent(
  event: ErrorEvent,
  hint: EventHint,
  isUnwrappedError: boolean
): { error: Error; event: ErrorEvent; shouldSkip?: boolean } {
  const error = hint.originalException;
  const isError = isErrorEvent(error);

  if (isUnwrappedError) {
    event.tags = event.tags || {};
    event.tags.errorType = 'unhandled-rejection';
  }

  if (isError) {
    return { error, event };
  }

  let finalError: Error;

  if (error instanceof Event) {
    const eventError = new EventError(error);
    finalError = eventError;

    const { target, targetAttributes } = eventError.recomputedEvent;
    if (
      !target ||
      (Object.keys(target).length === 0 &&
        (!targetAttributes || Object.keys(targetAttributes).length === 0))
    ) {
      return { error: finalError, event, shouldSkip: true };
    }
  } else {
    finalError = new NonObjectError(error);

    const errorAsError = error as Error;
    if (errorAsError.name === 'TypeError' && errorAsError.message === '') {
      return { error: finalError, event, shouldSkip: true };
    }
  }
  // overwrite default exception with the new Error data
  event.exception = {
    values: [
      {
        type: finalError.name,
        value: finalError.message,
      },
    ],
  };

  return { error: finalError, event };
}

export function isUnhandledRejectionEvent(
  e: unknown
): e is PromiseRejectionEvent {
  return e instanceof Event && e.type === 'unhandledrejection';
}
