export enum VatValueOptions {
  VAT_UNDEFINED = 'vat_undefined',
  VAT_2000 = 'vat_2000',
  VAT_1000 = 'vat_1000',
  VAT_850 = 'vat_850',
  VAT_550 = 'vat_550',
  VAT_210 = 'vat_210',
  VAT_0_FRA = 'vat_0_fra',
  VAT_0_UE = 'vat_0_ue',
  VAT_0_OTHER = 'vat_0_other',
  VAT_0_FRAAUTOLIQUIDE = 'vat_0_fraautoliquide',
}

export const VAT_VALUES = ['0', '210', '550', '850', '1000', '2000'];
