import type { CreateOrUpdateSalesReportDto } from '@legalplace/bankroot-api/modules/accountingCollaborator/salesReport/dto';
import type {
  GetSalesReportDto,
  SalesReportsHistoryResponse,
} from '@legalplace/bankroot-api/modules/salesReport/dto';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Status } from '../../constants';
import { reactToolkitBaseQuery } from '../../services/api/api';

import type { SalesReportsState } from './types';

const initialState: SalesReportsState = {
  monthReportsByMonthAndYear: {},
  status: Status.LOADING,
  pageStatus: Status.IDLE,
  lastStartDateFetched: undefined,
};

export const salesReportsSlice = createSlice({
  name: 'salesReports',
  initialState,
  reducers: {
    startFetchSalesMonthReportsRequest: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) => {
      state.pageStatus = Status.LOADING;
      state.lastStartDateFetched = action.payload.startDate;
    },
    fetchSalesMonthReportsSuccess: (
      state,
      action: PayloadAction<SalesReportsHistoryResponse[]>
    ) => {
      state.monthReportsByMonthAndYear = action.payload.reduce(
        (acc, monthReport) => ({
          ...acc,
          [`${monthReport.month}-${monthReport.year}`]: monthReport,
        }),
        state.monthReportsByMonthAndYear
      );
      state.status = Status.IDLE;
      state.pageStatus = Status.IDLE;
    },
    failSalesMonthReportsRequest: (state) => {
      state.status = Status.FAILED;
    },
    resetSalesReports: () => initialState,
  },
});

export const salesReportApi = createApi({
  reducerPath: 'salesReportApi',
  baseQuery: reactToolkitBaseQuery,
  tagTypes: ['SalesReport'],
  endpoints: (build) => ({
    getSalesReport: build.query<
      GetSalesReportDto,
      { companyId: string; salesReportId: string }
    >({
      query: ({ companyId, salesReportId }) => ({
        url: `/company/${companyId}/sales-report/${salesReportId}`,
        method: 'GET',
      }),
      providesTags: ['SalesReport'],
    }),
    upsertSalesReport: build.mutation<
      GetSalesReportDto,
      {
        companyId: string;
        createOrUpdateSalesReportDto: CreateOrUpdateSalesReportDto;
      }
    >({
      query: ({ companyId, createOrUpdateSalesReportDto }) => ({
        url: `/company/${companyId}/sales-report`,
        method: 'POST',
        body: createOrUpdateSalesReportDto,
      }),
      invalidatesTags: ['SalesReport'],
    }),
  }),
});

export const { useGetSalesReportQuery, useUpsertSalesReportMutation } =
  salesReportApi;

export default salesReportsSlice.reducer;
