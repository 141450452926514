import { MAXIMUM_FILE_SIZE } from '@legalplace/shared';

export const fileSelector = {
  file_max_size_error: `La taille maximale autorisée est de ${MAXIMUM_FILE_SIZE.toString().slice(
    0,
    -6
  )}MB.`,
  entity_too_large: 'Le fichier est trop volumineux.',
  unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
};
