import { PaymentType } from '@legalplace/bankroot-api/constants';
import { SalesType, StockGeography } from '@legalplace/shared';

import { VatValueOptions } from '../../constants';
import { UpsertSalesReportSteps } from '../../constants/salesReport/modalSteps.constants';

export const salesReport = {
  upsert_modal: {
    modal_title: 'Compléter ma déclaration',
    back_button_label: 'Retour',
    next_step_button_label: 'Continuer',
    cancel_button_label: 'Annuler',
    save_button_label: 'Valider ma déclaration',
    success_message: 'Votre déclaration a bien été enregistrée.',
    error_message: 'Une erreur est survenue.',
    steps: {
      [UpsertSalesReportSteps.MY_SALES]: '1. Mes ventes',
      [UpsertSalesReportSteps.MY_PAYMENT_METHODS]:
        "2. Mes moyens d'encaissement",
      [UpsertSalesReportSteps.MY_FEES]: '3. Mes commissions',
      [UpsertSalesReportSteps.MY_PROOFS]: '4. Mes justificatifs',
    },
    sales_tab: {
      banner: {
        title:
          'Détaillez vos ventes de {{monthAndYear}} effectuées sur votre canal "{{salesChannelLabel}}"',
        description:
          "Vous n'êtes pas sûrs de comment remplir cette déclaration? Consultez notre guide.",
        button: 'Trouver mes informations',
      },
      headers: {
        salesType: 'Type de vente',
        country: 'Pays du client',
        stockGeography: 'Expédié depuis',
        amount: 'Montant TTC',
      },
      inputs: {
        sales_type_dropdown_placeholder: 'Choisir le type de vente',
        country_dropdown_placeholder: 'Choisir le pays',
        stockGeography_dropdown_placeholder: 'Choisir la zone',
        save_amount: 'Valider',
        vat_dropdown_placeholder: 'TVA %',
        empty_stock_geography: 'Non applicable',
        [StockGeography.EU]: 'UE (Hors France)',
        [StockGeography.OUTSIDE_EU]: 'Hors UE',
        [StockGeography.FRANCE]: 'France',
        [SalesType.MerchandiseSale]: 'Marchandises',
        [SalesType.FinishedProductSale]: 'Produits finis',
        [SalesType.Service]: 'Services',
        amount_error_message: 'Remplir ce champ.',
        vat_list_label: 'TVA {{amount}} %',
      },
      add_new_sales_row_button_label: 'Déclarer une nouvelle vente',
      empty_sales_table: 'Aucune vente enregistrée',
    },
    payment_method_tab: {
      banner: {
        title: 'Comment avez-vous encaissé ces {{salesAmount}} ?',
        description:
          "Vous devez répartir le montant déclaré à l'étape précédente entre vos différents moyens d'encaissement.",
        button: 'Comment répartir mes informations',
      },
      headers: {
        payment_method: "Moyens d'encaissement",
        amount: 'Montant encaissé',
        [PaymentType.Card]: 'Carte bancaire',
        [PaymentType.Cash]: 'Espèces',
        [PaymentType.Check]: 'Chèques',
        [PaymentType.MealVoucher]: 'Carte/Tickets Restaurant',
        [PaymentType.Credit]: 'Avoirs et bons de réductions',
      },
      inputs: {
        payment_method_dropdown_placeholder: "Choisir le moyen d'encaissement",
        save_amount: 'Valider',
      },
      amount_error_title: '{{paymentMethodAmount}} / {{salesAmount}}',
      positive_amount_error_message: 'Il reste {{amount}} à répartir',
      negative_amount_error_message: 'Vous avez réparti {{amount}} en trop',
    },
    fees_tab: {
      banner: {
        title: "Déclarez les commissions prises par vos moyens d'encaissement",
        description:
          'Ces informations sont disponibles depuis votre rapport de vente général.',
        button: 'Trouver mes informations',
      },
      headers: {
        payment_method: "Moyens d'encaissement",
        amount: 'Commission',
      },
      inputs: {
        payment_method_dropdown_placeholder: "Choisir le moyen d'encaissement",
        save_amount: 'Valider',
        no_fee_comission_toggle_label:
          "Mes moyens d'encaissement ne prennent pas de commissions",
        vat_dropdown_placeholder: 'TVA %',
        vat_dropdown: {
          [VatValueOptions.VAT_0_FRA]: 'TVA 0 % - France',
          [VatValueOptions.VAT_0_UE]: 'TVA 0 % - UE',
          [VatValueOptions.VAT_0_OTHER]: 'TVA 0 % - Hors UE',
          [VatValueOptions.VAT_0_FRAAUTOLIQUIDE]:
            'TVA 0 % - France - Autoliquidée',
          [VatValueOptions.VAT_210]: 'TVA 2,1 %',
          [VatValueOptions.VAT_550]: 'TVA 5,5 %',
          [VatValueOptions.VAT_850]: 'TVA 8,5 %',
          [VatValueOptions.VAT_1000]: 'TVA 10 %',
          [VatValueOptions.VAT_2000]: 'TVA 20 %',
          [VatValueOptions.VAT_UNDEFINED]: 'TVA',
        },
      },
    },
    proofs_tab: {},
  },
  page_title: 'Déclaration des ventes',
  banner: {
    title: {
      completed: 'Toutes vos déclarations de ventes sont à jour',
      not_completed:
        '{{ remainingReportsToComplete }} déclarations de ventes à compléter',
    },
    description: {
      completed:
        'Déclarez vos ventes chaque mois pour une TVA toujours exacte.',
      not_completed:
        'Votre balance de TVA est actuellement approximative. Complétez toutes vos déclarations de vente pour voir votre balance de TVA exacte.',
    },
    more_button: 'En savoir plus',
  },
  month_row: {
    badge_labels: {
      generated_draft: 'A compléter',
      completed_by_client: 'Complété',
      missing_proof: 'Justificatif manquant',
      closure_preparation: 'Préparation du Bilan',
      period_closed: 'Exercice clôturé',
    },
    amount_labels: {
      completed_report: {
        totalAmount: 'CA TTC déclaré',
        turnover: 'CA HT déclaré',
        vat: 'TVA collectée',
      },
      not_completed_report: {
        totalAmount: 'CA TTC projeté',
        turnover: 'CA HT projeté',
        vat: 'TVA projetée',
      },
      commission: 'Commission',
    },
    amount_helpers: {
      completed_report: {
        totalAmount:
          "Chiffre d'affaires total déclaré pour ce mois, incluant toutes les taxes.",
        turnover: "Chiffre d'affaires déclaré pour ce mois, hors taxes.",
        vat: 'Montant total de la TVA collectée ce mois.',
      },
      not_completed_report: {
        totalAmount:
          "Chiffre d'affaires estimé pour ce mois, incluant toutes les taxes, basé sur vos encaissements et vos ventes déclarées.",
        turnover:
          "Chiffre d'affaires estimé pour ce mois, hors taxes, obtenu en déduisant la TVA projetée du CA TTC.",
        vat: 'Montant estimé de la TVA collectée ce mois, calculé sur la base du CA TTC et du taux moyen applicable à votre activité.',
      },
    },
    button_labels: {
      no_sales: 'Aucune vente à déclarer',
      create_report: 'Compléter ma déclaration',
      update_report: 'Modifier ma déclaration',
      add_proof: 'Ajouter un justificatif',
    },
    no_reports: 'Aucune déclaration réalisée',
  },
};
