import {
  CategorizableItemStatus,
  CategorizableItemType,
  SalesChannelStatus,
  SalesType,
  StockGeography,
  UserCompanyStatus,
} from '@legalplace/shared';

import { UserPosition } from '../../constants/companyUser.constants';
import { EditPhoneNumberStep } from '../../constants/phoneSettings.constants';

export const settings = {
  page_title: 'Paramètres',
  back_button: 'Retour',
  settings_navigation_list: {
    general_company_settings_label: 'Informations générales',
    account_settings: 'Mon compte',
    policies: {
      terms_of_service_label: "Conditions générales d'utilisation",
      privacy_policy_label: ' Politique de confidentialité des données',
      personal_data_label: 'Données personnelles',
      terms_of_service_accounting: 'Conditions générales ComptaPlace',
    },
  },
  settings_navigation_details: {
    email: {
      title: 'Adresse e-mail',
      submit_button_label: 'Enregistrer',
      invalid_email_input: 'Cette adresse e-mail est invalide.',
      invalid_email_domain_input:
        'Les adresses mails issues de ce domaine ne sont pas autorisées.',
      success_email_update: 'Adresse e-mail modifiée !',
      email_label: 'E-mail',
      unlock_edit_label: 'Modifier mon adresse email',
      mfa_modal_title: 'Modifiez votre adresse e-mail',
      mfa_confirmation_modal_title: 'Confirmez le changement',
    },
    company: {
      company_information_section: 'Informations société',
      company_head_office_section: 'Siège social',
      company_leaders_section: 'Dirigeants',
      company_associates_section: 'Associés',
      head_office: 'Adresse',
      delivery_form_title: 'Adresse des locaux',
      address_1_label: 'Adresse',
      address_2_label: "Complément d'adresse (optionnel)",
      zip_code_label: 'Code postal',
      city_label: 'Ville',
      country_label: 'Pays',
      submit_button_label: 'Enregistrer',
      general_information_title: 'Informations générales',
      country_FRA: 'FRANCE',
      company_name: 'Dénomination sociale',
      company_siren: 'SIREN',
      legal_status: 'Forme juridique',
      vat_plan: 'Régime de TVA',
      not_subject_to_vat: 'Non assujetti à la TVA',
      vat_plan_Mensuel: 'Mensuelle',
      vat_plan_Annuel: 'Annuelle',
      vat_plan_Trimestriel: 'Trimestrielle',
      vat_plan_Exonéré: 'Exonéré de TVA',
      success_update_message: 'Adresse des locaux modifiée !',
      address_error_message:
        "Renseignez une adresse qui n'est pas celle de votre domiciliation.",
      categorizable_items: {
        archived_label: 'Archivé',
        restore_btn_label: 'Restaurer',
      },
    },
    tax_credentials: {
      title: 'Identifiants impots.gouv',
      info_box_title: 'Vos identifiants sont essentiels',
      info_box_message:
        'Vos identifiants permettent à votre comptable de faire toutes les déclarations pour votre entreprise.',
      learn_more_button_label: 'En savoir plus',
      email_field: 'Identifiant',
      email_placeholder: 'Exemple@email.fr',
      password_placeholder: 'Mot de passe',
      password_field: 'Mot de passe',
      secret_question_field: 'Réponse à la question secrète',
      secret_question_tooltip:
        "Il s'agit de la réponse donnée à la question secrète lors de la création de votre compte impots.gouv.",
      secret_question_placeholder: 'Votre réponse',
      encrypted_informations: 'Vos informations sont chiffrées.',
      processing_information:
        "Pour des raisons de sécurité, nous n'affichons plus vos identifiants une fois collectés.",
      submit_button_label: 'Valider vos identifiants',
      edit_button_label: 'Actualiser vos identifiants',
      error: {
        same_password: `Merci d'entrer un mot de passe différent de celui précédemment enregistré.`,
      },
      modal_title: 'Saisissez vos identifiants impots.gouv',
    },
    categorizable_item: {
      title: {
        [CategorizableItemType.VEHICULE_PRO]: 'Véhicules professionnels',
        [CategorizableItemType.FILIALE]: 'Filiales',
        [CategorizableItemType.ASSURANCE]: 'Assurances',
        [CategorizableItemType.EMPRUNT]: 'Emprunts',
        [CategorizableItemType.CREDIT_BAIL]: 'Crédits-Bails',
      },
      add_button_label: {
        [CategorizableItemType.VEHICULE_PRO]:
          'Ajouter un véhicule professionnel',
        [CategorizableItemType.FILIALE]: 'Ajouter une filiale',
        [CategorizableItemType.ASSURANCE]: 'Ajouter une assurance',
        [CategorizableItemType.EMPRUNT]: 'Ajouter un emprunt',
        [CategorizableItemType.CREDIT_BAIL]: 'Ajouter un crédit-bail',
      },
      empty_message: {
        [CategorizableItemType.VEHICULE_PRO]:
          'Aucun véhicule professionnel enregistré',
        [CategorizableItemType.FILIALE]: 'Aucune filiale enregistrée',
        [CategorizableItemType.ASSURANCE]: 'Aucune assurance enregistrée',
        [CategorizableItemType.EMPRUNT]: 'Aucun emprunt enregistré',
        [CategorizableItemType.CREDIT_BAIL]: 'Aucun crédit-bail enregistré',
      },
      confirmation_modal: {
        [CategorizableItemStatus.Active]: {
          title: 'Souhaitez-vous réellement archiver "{{ciName}}" ?',
          confirm_button_label: 'Oui, archiver',
        },
        [CategorizableItemStatus.Archived]: {
          title: 'Souhaitez-vous restaurer "{{ciName}}" ?',
          confirm_button_label: 'Oui, restaurer',
        },
        cancel_button_label: 'Annuler',
      },
      success_message: {
        [CategorizableItemStatus.Active]: '{{ciName}} a bien été restauré !',
        [CategorizableItemStatus.Archived]: '{{ciName}} a bien été archivé !',
      },
      tooltip: {
        message:
          "Les éléments catégorisables permettent d'apporter des précisions sur certaines catégories déjà existantes.",
        help_button_label: 'En savoir plus',
      },
      empty_add_button_label: 'Ajouter',
      add_ci_success_message: '{{ciName}} a bien été créé !',
      edit_ci_success_message: 'Vos modifications ont bien été enregistrées !',
      archived_label: 'Archivé',
      restore_btn_label: 'Restaurer',
    },
    sales_channel: {
      new_sales_channel: {
        [SalesType.MerchandiseSale]: 'Marchandises',
        [SalesType.FinishedProductSale]: 'Produits finis',
        [SalesType.Service]: 'Services',
        [StockGeography.FRANCE]: 'France',
        [StockGeography.EU]: 'Union Européenne (hors France)',
        [StockGeography.OUTSIDE_EU]: 'Hors Union Européenne',
        information_title: 'Informations',
        sales_channel_name_label: 'Nom du canal de vente',
        sales_channel_name_placeholder: 'Indiquer le nom du canal de vente',
        sales_channel_type_label: 'Type de canal de vente',
        sales_channel_type_search_placeholder: 'Rechercher un canal de vente',
        empty_sales_channel_type_label_title: 'Aucun résultat',
        empty_sales_channel_type_label_subtitle:
          "Demander l'ajout de mon canal de vente",
        sales_channel_type_placeholder: 'Indiquer le canal de vente',
        payment_method_title: 'Service de paiement',
        add_payment_method_button_label: 'Créer un service de paiement',
        payment_method_dropdown_label: 'Service de paiement',
        new_payment_method_dropdown_placeholder:
          'Sélectionner un service de paiement',
        add_payment_method_dropdown_placeholder:
          'Ajouter un autre service de paiement',
        sales_type_title: 'Vente',
        stock_title: 'Stock',
        stock_subtitle: "J'expédie mes ventes depuis :",
        sales_type_subtitle: 'Je vends des :',
        add_button_label: 'Ajouter le canal de vente',
        add_success_message: '{{label}} a bien été créé !',
        edit_success_message: 'Vos modifications ont bien été enregistrées !',
        edit_button_label: 'Modifier le canal de vente',
        already_taken_name_error_message:
          'Ce nom de canal de vente existe déjà',
        empty_type_error_message: 'Veuillez sélectionner un canal de vente',
        empty_name_error_message: 'Ce champ ne peut rester vide',
        empty_payment_method_error_message:
          'Veuillez sélectionner un service de paiement',
        empty_stock_geography_error_message:
          'Veuillez sélectionner une des options ci-dessous',
        archive_button_label: 'Voir les services de paiement archivés',
      },
      error_message: {
        sales_channel_error: 'Ajouter un service de paiement',
        action_required_label: 'Action requise',
      },
      archived_label: 'Archivé',
      error_label: 'Action requise',
      restore_btn_label: 'Restaurer',
      empty_message: 'Aucun canal de vente enregistré',
      add_button_label: 'Ajouter un canal de vente',
      title: 'Canaux de vente',
      new_sales_channel_title: 'Nouveau canal de vente',
      edit_sales_channel_title: 'Modifier un canal de vente',
      confirmation_modal: {
        [SalesChannelStatus.Active]: {
          title: 'Souhaitez-vous réellement archiver "{{label}}" ?',
          confirm_button_label: 'Oui, archiver',
        },
        [SalesChannelStatus.Archived]: {
          title: 'Souhaitez-vous restaurer "{{label}}" ?',
          confirm_button_label: 'Oui, restaurer',
        },
        cancel_button_label: 'Annuler',
      },
      success_message: {
        [SalesChannelStatus.Active]: '{{label}} a bien été restauré !',
        [SalesChannelStatus.Archived]: '{{label}} a bien été archivé !',
      },
      tooltip: {
        message:
          'Les canaux de vente permettent de mieux piloter votre activité',
        help_button_label: 'En savoir plus',
      },
      confirm_navigation_modal: {
        cancel_add_message:
          'Souhaitez-vous vraiment annuler la création de votre canal de vente ?',
        cancel_edit_message: `Souhaitez-vous vraiment annuler la modification de votre canal de vente ?`,
        confirm_navigation_label: 'Oui, annuler',
        cancel_navigation_label: 'Non',
      },
    },
    payment_method: {
      add_modal_title: 'Ajouter un service de paiement',
      edit_modal_title: 'Modifier un service de paiement',
      new_payment_method_type_form_label: 'Service de paiement',
      new_payment_method_type_form_placeholder:
        'Sélectionnez un service de paiement',
      new_payment_method_name_form_placeholder: 'Mes revenus Shopify',
      new_payment_method_name_form_label: 'Nom du service de paiement',
      add_button_label: 'Ajouter',
      edit_button_label: 'Modifier',
      cancel_button_label: 'Annuler',
      search_placeholder: 'Rechercher un service de paiement',
      empty_search_title: 'Aucun résultat',
      empty_search_subtitle: "Demander l'ajout de mon service de paiement",
      existing_name_error: 'Ce nom de service de paiement existe déjà',
      archive_button_label: 'Archiver le service de paiement',
      no_payment_method_type_error: 'Vous devez choisir un service de paiement',
      edit_success_message: 'Vos modifications ont bien été enregistrées !',
      add_success_message: '{{label}} a bien été créé !',
      archive_success_message: '{{label}} a bien été archivé !',
      archive_modal: {
        title: 'Services de paiement archivés',
        restore_button_label: 'Restaurer',
        restore_success_message: '{{label}} a été restauré !',
      },
    },
    cancel_button_label: 'Annuler',
    phone: {
      title: 'Numéro de téléphone',
      steps: {
        [EditPhoneNumberStep.FILE_IMPORT]: 'Identité',
        [EditPhoneNumberStep.FILE_CONFIRMATION]: 'Vérification',
        [EditPhoneNumberStep.NEW_PHONE_NUMBER_INPUT]: 'Nouveau numéro',
        [EditPhoneNumberStep.NEW_PHONE_NUMBER_CONFIRMATION]: 'Confirmation',
      },
      info_box_message:
        "Il s'agit d'une opération sensible qui doit être traitée par notre service client. Une fois la modification demandée, nous reviendrons vers vous par e-mail.",
      info_box_title: 'Modification de numéro',
      phone_label: 'Téléphone',
      update_phone_button_label: 'Modifier mon numéro de téléphone',
      phone_number_partner_pending:
        'Votre demande de changement a été prise en compte.',
      update_phone_import_file_message:
        "Pour vérifier qu'il s'agit bien de vous, importez dès maintenant une photo de vous tenant votre pièce d'identité.",
      modal_title: 'Modifier mon numéro',
      cancel_button_label: 'Annuler',
      edit_button_label: 'Modifier',
      back_button_label: 'Précédent',
      import_picture_label: 'Importer',
      continue_button_label: 'Continuer',
      file_confirmation_message:
        "Assurez-vous que votre visage et votre pièce d'identité sont clairement lisibles et identifiables.",
      new_phone_number_input_message:
        'Renseignez votre nouveau numéro de téléphone :',
      old_phone_number_label: 'Ancien numéro de téléphone',
      new_phone_number_label: 'Nouveau numéro de téléphone',
      confirm_phone_number_update_label: 'Confirmer',
      confirm_phone_number_update_message:
        'Merci de vérifier les informations ci-dessous :',
      success_title: 'Demande prise en compte',
      success_message: 'Nous revenons vers vous dès que possible.',
      close_button_label: 'Fermer',
      successfully_updated: 'Numéro de téléphone modifié !',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      invalid_format: 'Ce numéro de téléphone est invalide.',
      unlock_edit_label: 'Modifier mon numéro de téléphone',
      mfa_modal_title: 'Modifiez votre numéro de téléphone',
    },
    password: {
      title: 'Mot de passe',
      old_password: 'Ancien mot de passe',
      new_password: 'Nouveau mot de passe',
      confirm_password: 'Confirmer le nouveau mot de passe',
      submit_button_label: 'Enregistrer',
      unlock_edit_label: 'Modifier',
      mfa_modal_title: 'Modifiez votre mot de passe',
      old_password_incorrect: 'Ce mot de passe est incorrect.',
      new_password_incorrect:
        "Ce mot de passe n'est pas assez complexe pour sécuriser votre compte (10 caractères, avec une majuscule, une minuscule, un chiffre et un caractère spécial).",
      new_password_mismatch: 'Les mots de passe ne sont pas identiques.',
      success_password_update: 'Mot de passe modifié !',
      unknown_error: 'Une erreur est survenue, veuillez réessayer.',
    },
    notifications: {
      title: 'Notifications',
      transactions: 'Transactions',
      cards: 'Cartes',
      credit_transfer_received: {
        label: 'Virements reçus',
        message:
          'Être notifié lorsque le compte est crédité par un virement entrant',
      },
      low_balance: {
        label: 'Solde bas',
        message:
          'Être notifié lorsque le solde du compte est inférieur au seuil défini',
      },
      card_shipped: {
        label: 'Expédition',
        message: 'Être notifié lorsqu’une de mes cartes est expédiée',
      },
      email: 'E-mail',
      threshold: 'Seuil',
      save_amount: 'Valider',
    },
    leaders_and_associates: {
      title: 'Dirigeants et associés',
      leaders_section_label: 'Dirigeants',
      associates_section_label: 'Associés',
    },
    invoices: {
      title: 'Factures',
      description:
        'Retrouvez vos factures et gérez vos méthodes de paiement via notre plateforme dédiée. Une authentification forte sera requise.',
      button_label: 'Consulter mes factures',
    },
    users: {
      title: 'Utilisateurs',
      add_button_label: 'Ajouter un utilisateur',
      add_user: {
        modal_title: 'Ajouter un utilisateur',
        success_text_message: `{{newUserName}} vient de recevoir un e-mail pour définir son mot de passe et accéder à son compte LegalPlace Pro.`,
        failed_consent_title: `Ajout annulé`,
        failed_consent_message: `Vous avez annulé l'ajout de cet utilisateur.`,
        info_step: {
          body_title: 'Nouvel accès à LegalPlace Pro',
          body_text: `Cet utilisateur pourra effectuer les mêmes actions que vous sur LegalPlace Pro, mais vous resterez le seul à pouvoir activer et désactiver les accès de l'équipe.`,
          confirm_user_add_label: 'Oui, ajouter',
          close_modal_label: 'Annuler',
          info_message: `Assurez-vous d'avoir votre smartphone`,
        },
        form_step: {
          ci_input_label: 'Associé',
          firstname: 'Prénom',
          lastname: 'Nom',
          phone_number: 'Téléphone',
          email: 'E-mail',
          position: 'Fonction',
          next_step_button_label: 'Oui, ajouter',
          cancel_button_label: 'Annuler',
          [UserPosition.ACCOUNTANT]: 'Comptable',
          [UserPosition.ASSOCIATE]: 'Associé',
          [UserPosition.EMPLOYEE]: 'Salarié',
          [UserPosition.EXECUTIVE]: 'Dirigeant',
          [UserPosition.OTHER]: 'Autre',
          other_position: 'Autre fonction',
          other_position_placeholder: 'Préciser',
          conflict_error_message:
            'Un utilisateur avec cet e-mail ou ce numéro existe déjà.',
          unknown_error: 'Une erreur est survenue, veuillez réessayer.',
          info_message:
            'Ces informations seront modifiables par votre associé une fois son compte créé.',
          associate_placeholder_label: 'Sélectionnez un associé',
        },
        success_step: {
          body_title: 'Invitation envoyée !',
          body_text: `{{newUserName}} vient de recevoir un e-mail pour définir son mot de passe et accéder à son compte LegalPlace Pro.`,
          continue_button_label: 'Fermer',
        },
        success_consent_title: 'Invitation envoyée !',
        close_modal_button_label: `Retourner à l'accueil`,
      },
      deactivate_user_modal: {
        modal_title: 'Désactiver l’accès',
        deactivate_subtitle: 'Désactiver l’accès de {{userFirstName}} ?',
        deactivate_warning_message:
          '{{userFirstName}} ne pourra plus se connecter à LegalPlace Pro.',
        cancel_button: 'Annuler',
        submit_button: 'Oui, désactiver',
        success_message: '{{userFirstName}} n’a plus accès à LegalPlace Pro !',
      },
      reactivate_user_modal: {
        modal_title: 'Réactiver l’accès',
        reactivate_subtitle: 'Réactiver l’accès de {{userFirstName}} ?',
        reactivate_warning_message:
          '{{userFirstName}} pourra de nouveau se connecter à LegalPlace Pro.',
        cancel_button: 'Annuler',
        submit_button: 'Oui, réactiver',
        success_consent_title: 'Utilisateur réactivé',
        success_text_message: "L'utilisateur a bien été réactivé.",
        close_modal_button_label: 'Fermer',
        failed_consent_message:
          'Vous avez annulé la réactivation de cet utilisateur.',
        failed_consent_title: "Réactivation de l'accès annulée",
      },
      resend_invitation_button: 'Renvoyer l’invitation',
      delete_invitation_button: 'Supprimer',
      deactivate_button: `Désactiver l'accès`,
      reactivate_access_button: `Réactiver l'accès`,
      associate_banner: {
        title: 'Accès dédiés aux associés',
        message: `Seuls les associés peuvent avoir accès à l'application LegalPlace Pro car celle-ci comporte des informations bancaires sensibles.`,
      },
      status_badge_label: {
        [UserCompanyStatus.DEACTIVATED]: 'Désactivé le {{date}}',
        [UserCompanyStatus.ACTIVE]: 'Actif depuis le {{date}}',
        [UserCompanyStatus.INVITATION_EMAIL_SENT]:
          'Invitation envoyée le {{date}}',
      },
      invitation_resent: 'Invitation renvoyée !',
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      invitation_deleted: 'L’invitation a été supprimée !',
    },
  },
};
