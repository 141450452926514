import { WithContextError } from '../WithContextError';

import { recomputeEventError } from './EventError.helper';

export class EventError extends WithContextError {
  public recomputedEvent: Record<string, unknown>;

  constructor(event: Event) {
    const recomputedEvent = recomputeEventError(event);
    super(`Error received is an event`, 'Event Values', recomputedEvent);
    this.name = 'EventError';
    this.recomputedEvent = recomputedEvent;
  }
}
