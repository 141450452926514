export function getAllObjectKeys(
  obj: object,
  acc: string[],
  maxRecursionDepth = 5
): string[] {
  if (maxRecursionDepth <= 0) {
    return acc;
  }
  const ret = acc.concat(Object.getOwnPropertyNames(obj));
  try {
    return getAllObjectKeys(
      Object.getPrototypeOf(obj),
      ret,
      maxRecursionDepth - 1
    );
  } catch (err: unknown) {
    return acc;
  }
}

const getEventTargetAttributes = (
  event: Event
): Record<string, unknown> | null => {
  const eventTarget = event.target as HTMLInputElement;
  return eventTarget?.getAttributeNames
    ? {
        targetAttributes: eventTarget.getAttributeNames().reduce(
          (acc, attribute) => ({
            ...acc,
            [attribute]: eventTarget.getAttribute(attribute),
          }),
          {}
        ),
      }
    : null;
};

export function recomputeEventError(e: Event): Record<string, unknown> {
  const eventObject = JSON.parse(JSON.stringify(e, getAllObjectKeys(e, [])));
  const targetAttributes = getEventTargetAttributes(e);

  return {
    ...eventObject,
    ...targetAttributes,
  };
}
