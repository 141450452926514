import { styled, Toggle as SbToggle } from '@legalplace/storybook';

export const Toggle = styled(SbToggle)`
  input:checked + span {
    background-color: ${({ theme }) => theme.backgroundColors.primary500};
  }
  p {
    cursor: pointer;
  }
`;
