import { VatValueOptions } from '../../constants/vat.constants';

export const directDebit = {
  page_title: 'Prélèvements',
  mandates: {
    page_title: 'Mandats',
    searchbar_placeholder: 'Nom du mandat',
    reset_button: 'Réinitialiser',
    new_partner: 'Ajouter un mandat',
    number_of_partners_zero: 'Aucun mandat',
    number_of_partners_one: '1 mandat',
    number_of_partners_other: '{{ count }} mandats',
    validate_mandate_button: 'Approuver',
    mandate_suspend_modal: {
      modal_title: 'Supprimer un mandat',
      delete_subtitle: 'Supprimer "{{creditorName}}" ?',
      delete_success_message: 'Mandat supprimé !',
      delete_warning_message:
        'Cette action est irréversible. Nous refuserons systématiquement tout prélèvement futur sur votre compte via ce mandat.',
      cancel_button: 'Annuler',
      primary_button: 'Oui, supprimer',
    },
    mandate_add_modal: {
      modal_title: 'Ajouter un mandat',
      modal_information:
        'Veuillez reporter les informations qui figurent sur le mandat de prélèvement B2B (interentreprises) que votre partenaire vous a fourni.',
      mandate_reference_label: 'Référence Unique de Mandat (RUM)',
      creditor_name_label: 'Nom du créancier',
      creditor_identifier_label: 'Identifiant créancier SEPA',
      cancel_button: 'Annuler',
      primary_button: 'Ajouter',
      success_consent_title: 'Mandat ajouté !',
      modal_cancellation_button: 'Fermer',
      close_modal_button_label: 'Fermer',
      success_text_message: `Votre partenaire peut désormais prélever votre compte. Vous avez la possibilité de supprimer ce mandat à tout moment.`,
      failed_consent_title: 'Ajout de mandat annulé',
      failed_consent_message: `Vous avez annulé l'ajout de ce mandat.`,
      mandate_already_exists_error: 'Ce mandat a déjà été ajouté !',
      trying_to_add_suspended_mandate_error:
        'Ce mandat a été ajouté et supprimé\u00A0! Pour le réactiver, veuillez contacter le support pour faire une demande spécifique.',
    },
    mandate_enable_modal: {
      modal_title: 'Approuver un mandat',
      modal_information: `Les informations ci-dessous correspondent au mandat de prélèvement que nous avons reçu. Ce mandat étant au format B2B (interentreprises), <strong>il nécessite votre approbation avant d'être effectif</strong>. Sans action de votre part, nous refuserons pour le moment toute tentative de prélèvement.`,
      mandate_reference_label: 'Référence Unique de Mandat (RUM)',
      creditor_name_label: 'Nom du créancier',
      creditor_identifier_label: 'Identifiant créancier SEPA',
      cancel_button: 'Annuler',
      primary_button: 'Approuver',
      success_consent_title: 'Mandat approuvé !',
      modal_cancellation_button: 'Fermer',
      close_modal_button_label: 'Fermer',
      success_text_message: `Votre partenaire peut désormais prélever votre compte. Vous avez la possibilité de supprimer ce mandat à tout moment.`,
      failed_consent_title: 'Approbation de mandat annulé',
      failed_consent_message: `Vous avez annulé l'approbation de ce mandat.`,
    },
  },
  transaction_dashboard: {
    title: 'Filtres {{currentActiveFilterCount}}',
    date_filter_title: 'Date',
    transaction_type_filter_title: 'Type',
    reset_all_button: 'Réinitialiser tout',
    apply_filters_button: 'Appliquer',
    credit: 'Crédit',
    debit: 'Débit',
    transaction_date: 'Date',
    transaction_type: 'Type',
    transaction_amount: 'Montant',
    footer_text_zero: '0 transaction',
    footer_text_one: '1 transaction',
    footer_text_other: '{{count}} transactions',
    transaction_dashboard_filter_result_text:
      '{{transactionCount}} transactions',
    transaction_dashboard_get_next_page_button: 'Voir plus',
    transaction_toolbar_export_button: 'Exporter',
    searchbar_placeholder: 'Libellé, montant...',
    transaction_dashboard_pending: 'En cours',
    transaction_dashboard_rejected: 'Rejeté',
    transaction_type_filter_button: 'Type',
    transaction_type_filter_action_button: 'Appliquer',
    transaction_type_reset_action_button: 'Réinitialiser',
    transaction_type_card_other: 'Paiement par carte',
    transaction_type_card_in_store: 'Paiement par carte (en magasin)',
    transaction_type_card_online: 'Paiement par carte (sur internet)',
    transaction_type_card_withdrawal: 'Retrait au distributeur',
    transaction_type_payment: 'Virement',
    transaction_type_levy: 'Prélèvement',
    transaction_type_expense: 'Note de frais',
    credit_debit_filter_button: 'Crédit / Débit',
    credit_debit_filter_action_button: 'Appliquer',
    credit_debit_reset_action_button: 'Réinitialiser',
    credit_debit_null_choice: 'Indifférent',
    date_filter_button: 'Date',
    date_filter_action_button: 'Appliquer',
    date_reset_action_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    mobile_filter_action_button: 'Filtrer',
    mobile_reset_action_button: 'Réinitialiser',
    mobile_filter_drawer_title: 'Filtrer',
    reset_button: 'Réinitialiser',
    from: 'Du',
    to: 'au',
    transaction_search_button: 'Recherche',
    no_item: 'Aucun prélèvement effectué pour le moment',
    no_item_subtitle: "Tous vos prélèvements s'afficheront ici.",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    transactionAmount_filter_title: 'Montant',
    amount_from: 'Entre',
    amount_to: 'Et',
    not_justified_label: 'À justifier',
    transaction_types: {
      Prélèvement: 'Prélèvement',
    },
    accounts_filter_button: 'Comptes',
    accounts_filter_action_button: 'Appliquer',
    accounts_reset_action_button: 'Réinitialiser',
    download_started: 'Téléchargement démarré !',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    multiple_categories: 'Catégories multiples',
    to_categorize: 'À catégoriser',
    vat_dropdown: {
      [VatValueOptions.VAT_0_FRA]: '0 % - France',
      [VatValueOptions.VAT_0_UE]: '0 % - UE',
      [VatValueOptions.VAT_0_OTHER]: '0 % - Hors UE',
      [VatValueOptions.VAT_0_FRAAUTOLIQUIDE]: '0 % - France - Autoliquidée',
      [VatValueOptions.VAT_210]: '2,1 %',
      [VatValueOptions.VAT_550]: '5,5 %',
      [VatValueOptions.VAT_850]: '8,5 %',
      [VatValueOptions.VAT_1000]: '10 %',
      [VatValueOptions.VAT_2000]: '20 %',
      [VatValueOptions.VAT_UNDEFINED]: 'TVA',
    },
    file_preview_title: 'Justificatif - {{fileName}}',
    today_date_row: 'Aujourd’hui',
    yesterday_date_row: 'Hier',
    accounting_ongoing: 'Préparation du bilan',
    accounting_closed: 'Exercice clôturé',
    transaction_not_categorized: 'Non catégorisée',
  },
};
