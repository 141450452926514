import { VehicleType } from '@legalplace/shared';

import {
  AddPersonalVehicleSteps,
  EngineOptions,
} from '../../constants/personalVehicle.constants';
import { VatValueOptions } from '../../constants/vat.constants';

export const expenses = {
  page_title: 'Notes de frais',
  add_expense: 'Ajouter une note de frais',
  tooltip: {
    message:
      "Les notes de frais viennent augmenter le compte courant d'associé.",
    help_button_label: 'En savoir plus',
  },
  transaction_dashboard: {
    title: 'Filtres {{currentActiveFilterCount}}',
    date_filter_title: 'Date',
    proofStatus_filter_title: 'Justificatif',
    operationsStatus_filter_title: 'Catégorisation',
    reset_all_button: 'Réinitialiser tout',
    apply_filters_button: 'Appliquer',
    transaction_date: 'Date',
    transaction_type: 'Type',
    transaction_amount: 'Montant',
    footer_text_zero: '0 transaction',
    footer_text_one: '1 transaction',
    footer_text_other: '{{count}} transactions',
    transaction_dashboard_filter_result_text:
      '{{transactionCount}} transactions',
    transaction_dashboard_get_next_page_button: 'Voir plus',
    transaction_toolbar_export_button: 'Exporter',
    searchbar_placeholder: 'Libellé, montant...',
    transaction_dashboard_pending: 'En cours',
    operations_status_filter_button: 'Catégorisation',
    proof_status_filter_action_button: 'Appliquer',
    proof_status_reset_action_button: 'Réinitialiser',
    missing_proof: 'Justificatifs obligatoires manquants',
    uploaded_proof: 'Justificatifs déposés',
    not_categorized: 'Non catégorisées',
    search_category_placeholder: 'Rechercher une catégorie',
    search_category_empty_state:
      'Aucune catégorie ne correspond à votre recherche.',
    manually_categorized: 'Catégorisation manuelle',
    ai_categorized: 'Catégorisation par IA',
    accounts_filter_button: 'Comptes',
    accounts_filter_action_button: 'Appliquer',
    accounts_reset_action_button: 'Réinitialiser',
    proof_status: {
      Missing: 'Manquants',
      Uploaded: 'Déposés',
    },
    operations_status: {
      NotCategorized: 'Transactions non catégorisées',
    },
    not_justified_label: 'À justifier',
    date_filter_button: 'Date',
    date_filter_action_button: 'Appliquer',
    date_reset_action_button: 'Réinitialiser',
    mobile_filter_button: 'Filtrer',
    mobile_filter_action_button: 'Filtrer',
    mobile_reset_action_button: 'Réinitialiser',
    mobile_filter_drawer_title: 'Filtrer',
    reset_button: 'Réinitialiser',
    from: 'Du',
    to: 'au',
    transaction_search_button: 'Recherche',
    no_item: 'Aucune note de frais pour le moment',
    no_item_subtitle: "Toutes vos notes de frais s'afficheront ici.",
    no_filtered_item: 'Aucun résultat avec les critères sélectionnés',
    no_filtered_item_subtitle: 'Essayez de réajuster votre recherche.',
    export_success: 'Téléchargement démarré !',
    unknown_error: 'Une erreur est survenue, veuillez réessayer.',
    transactionAmount_filter_title: 'Montant',
    amount_from: 'Entre',
    amount_to: 'Et',
    date_from: 'Date de début',
    date_to: 'Date de fin',
    transaction_types: {
      Carte: 'Paiement par carte',
      CarteMagasin: 'Paiement par carte (en magasin)',
      CarteEnLigne: 'Paiement par carte (sur internet)',
      RetraitDistributeur: 'Retrait au distributeur',
      Virement: 'Virement',
      VirementInstantané: 'Virement instantané',
      Prélèvement: 'Prélèvement',
      NoteDeFrais: {
        purchase: 'Note de frais (achats divers)',
        mileage_allowance: 'Note de frais (indemnité {{distance}} km)',
      },
    },
    download_started: 'Téléchargement démarré !',
    unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
    multiple_categories: 'Catégories multiples',
    to_categorize: 'À catégoriser',
    vat_dropdown: {
      [VatValueOptions.VAT_0_FRA]: '0 % - France',
      [VatValueOptions.VAT_0_UE]: '0 % - UE',
      [VatValueOptions.VAT_0_OTHER]: '0 % - Hors UE',
      [VatValueOptions.VAT_0_FRAAUTOLIQUIDE]: '0 % - France - Autoliquidée',
      [VatValueOptions.VAT_210]: '2,1 %',
      [VatValueOptions.VAT_550]: '5,5 %',
      [VatValueOptions.VAT_850]: '8,5 %',
      [VatValueOptions.VAT_1000]: '10 %',
      [VatValueOptions.VAT_2000]: '20 %',
      [VatValueOptions.VAT_UNDEFINED]: 'TVA',
    },
    file_preview_title: 'Justificatif - {{fileName}}',
    today_date_row: 'Aujourd’hui',
    yesterday_date_row: 'Hier',
    accounting_ongoing: 'Préparation du bilan',
    accounting_closed: 'Exercice clôturé',
    transaction_not_categorized: 'Non catégorisée',
  },
  edit_or_duplicate_expense_modal: {
    edit_expense_modal_title: 'Modifier une note de frais',
    duplicate_expense_modal_title: 'Dupliquer une note de frais',
  },
  add_expense_modal: {
    expense_modal_title: 'Ajouter une note de frais',
    new_vehicle_modal_title: 'Ajouter un nouveau véhicule personnel',
    expense_label_input_label: 'Motif (optionnel)',
    general_expense: {
      unexpected_error: 'Une erreur est survenue, veuillez réessayer.',
      tab_label: 'Achats divers',
      expense_date_label: 'Date de la dépense',
      expense_banner_message:
        'Vos notes de frais anticipées doivent être datées au commencement de votre activité qui aura lieu le {{ date }}.',
      expense_person_of_interest_label: 'Associé',
      add_expense_button_label: 'Ajouter',
      update_expense_button_label: 'Modifier',
      duplicate_expense_button_label: 'Dupliquer',
      cancel_button_label: 'Annuler',
      date_error_before_activity_start_date:
        "La date doit être postérieure à celle de votre début d'activité ({{ date }})",
      date_error_finished_accounting_period: `Bilan clôturé, la date doit correspondre à une période comptable ouverte.`,
      date_error_ongoing_collaborator_accounting_period: `Bilan en préparation, la date doit corresponde à une période comptable ouverte.`,
      tax_included_euros: '€ TTC',
    },
    mileage_allowance: {
      add_expense_button_label: 'Ajouter',
      tab_label: 'Indemnités kilométriques',
      add_vehicule_button_label: 'Ajouter un véhicule personnel',
      empty_vehicles_heading: 'Aucun véhicule personnel enregistré',
      empty_vehicles_body:
        "Il n'y a aucun véhicule personnel enregistré pour le moment. \nCliquez sur le bouton ci-dessous pour ajouter un véhicule et lui associer une indemnité kilométrique.",
      mileage_date_label: 'Date du trajet',
      mileage_vehicle_select_label: 'Véhicule',
      add_vehicle_button_label: 'Ajouter un véhicule',
      cancel_button_label: 'Annuler',
      date_error_before_activity_start_date:
        "La date doit être postérieure à celle de votre début d'activité ({{ date }})",
      date_error_finished_accounting_period:
        'Bilan clôturé, la date doit correspondre à une période comptable ouverte.',
      date_error_ongoing_collaborator_accounting_period:
        'Bilan en préparation, la date doit correspondre à une période comptable ouverte.',
      mileage_error: 'La distance ne doit pas dépasser {{maxDistance}} km.',
    },
    add_personal_vehicle_modal: {
      modal_title: 'Ajouter un nouveau véhicule personnel',
      steps: {
        [AddPersonalVehicleSteps.VEHICLE_TYPE]: 'Type de véhicule',
        [AddPersonalVehicleSteps.VEHICLE_IDENTITY]: 'Identité du véhicule',
        [AddPersonalVehicleSteps.VEHICLE_INFORMATION_SUMMARY]: 'Confirmation',
      },
      motor_type_input_title: 'Type de motorisation',
      vehicle_type_input_title: 'Type de véhicule',
      vehicle_engine_capacity_input_title: 'Cylindrée du véhicule',
      vehicle_identity_form_title: 'Identité du véhicule',
      back_button_label: 'Retour',
      next_step_button_label: 'Suivant',
      cancel_button_label: 'Annuler',
      vehicle_name_input_label: 'Nom du véhicule (optionnel)',
      vehicle_brand_input_label: 'Marque du véhicule',
      vehicle_registration_input_label: 'Immatriculation du véhicule',
      vehicle_tax_power_input_label: 'Puissance administrative (en CV)',
      vehicle_owner_input_label: 'Propriétaire du véhicule',
      vehicle_recap_title: 'Confirmation',
      check_information_message:
        'Vérifiez bien que ces informations sont correctes.',
      save_button_label: 'Confirmer',
      summary_table: {
        vehicle_owner_label: 'Propriétaire du véhicule',
        vehicle_name_label: 'Nom du véhicule',
        vehicle_type_label: 'Type du véhicule',
        vehicle_brand_label: 'Marque du véhicule',
        vehicle_registration_label: 'Immatriculation du véhicule',
        vehicle_motor_type_label: 'Type de motorisation',
        vehicle_tax_horse_power_label: 'Puissance administrative (en CV)',
        vehicle_engine_capacity_label: 'Cylindrée du véhicule',
        vehicle_type: {
          [VehicleType.MOTORCYCLE]: 'Deux-roues',
          [VehicleType.MOTORCYCLE50CC]: 'Deux-roues',
          [VehicleType.CAR]: 'Voiture',
        },
        vehicle_engine_type: {
          [EngineOptions.ELECTRIC]: 'Moteur éléctrique',
          [EngineOptions.COMBUSTION]: 'Thermique ou hybride',
        },
        vehicle_engine_capacity: {
          [VehicleType.MOTORCYCLE]: 'Supérieure ou égale à 50 cm\u00b3',
          [VehicleType.MOTORCYCLE50CC]: 'Inférieure à 50 cm\u00b3',
        },
      },
      new_vehicle_success_message: 'Véhicule personnel enregistré !',
      tooltips: {
        vehicle_engine_capacity_tooltip:
          'Cette information est indiquée sur la carte grise du véhicule à la case P1.',
        vehicle_tax_horse_power_tooltip:
          'Cette information est indiquée sur la carte grise du véhicule à la case P6.',
      },
    },
  },
};
