import React from 'react';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import axios from 'axios';
import i18n from 'i18next';

import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { App as AppCapacitor } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';

import { ampli } from './ampli/api';
import { setupApiInterceptors } from './services/api/api.interceptor';
import {
  isErrorEvent,
  isUnhandledRejectionEvent,
  parseCookies,
  recomputeErrorAndEvent,
} from './services/utils';
import { recomputeEventError } from './services/utils/errors/EventError/EventError.helper';
import { stringifyNonObjectError } from './services/utils/errors/NonObjectError/NonObjectError.helper';
import { WithContextError } from './services/utils/errors/WithContextError';
import { englishTranslations } from './translations/english';
import { frenchTranslations } from './translations/french';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
}

i18n.use(initReactI18next).init({
  resources: {
    fr: { ...frenchTranslations },
    en: { ...englishTranslations },
  },
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
});

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'development') {
  window.addEventListener('unhandledrejection', async (event) => {
    event.preventDefault();
    let error;
    try {
      // This promise will be rejected
      await event.promise;
    } catch (err: unknown) {
      error = err;
    }

    if (!isErrorEvent(error)) {
      if (error instanceof Event) {
        Sentry.captureException(
          new Error(
            `Error received in unhandled rejection is an event: ${JSON.stringify(
              recomputeEventError(error)
            )}`
          ),
          {
            tags: {
              errorType: 'unhandled-rejection',
            },
          }
        );
      }
      Sentry.captureException(
        new Error(
          `Object received in unhandled rejection is not an error: ${stringifyNonObjectError(
            error
          )}`
        ),
        {
          tags: {
            errorType: 'unhandled-rejection',
          },
        }
      );
    }
    Sentry.captureException(error, {
      tags: {
        errorType: 'unhandled-rejection',
      },
    });
  });

  Sentry.init({
    dsn: 'https://730ec7252d497a8b48ad4ec4dcb70dda@o4508183515561984.ingest.de.sentry.io/4508319921930320',
    release: import.meta.env.VITE_APP_VERSION,
    tracesSampleRate: 0.0,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'The object can not be found here',
    ],
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    allowUrls: [import.meta.env.VITE_APP_URL],
    maxValueLength: 512,
    // Need any otherwise the unhandled rejection error evaluates to never because sentry's type is incorrect
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeSend: async (e: any, hint) => {
      let unwrappedError = e;
      let isUnwrappedError = false;
      if (isUnhandledRejectionEvent(e)) {
        isUnwrappedError = true;
        try {
          await e.promise;
        } catch (err) {
          unwrappedError = err as ErrorEvent;
        }
      }

      if (unwrappedError === null || unwrappedError === undefined) {
        return null;
      }

      const { error, event, shouldSkip } = recomputeErrorAndEvent(
        unwrappedError,
        hint,
        isUnwrappedError
      );

      if (shouldSkip) {
        return null;
      }

      event.tags = event.tags || {};

      const { message } = error;

      if (/Loading (CSS )?chunk [\d]+ failed/.test(message)) {
        event.tags.knownErrorType = 'chunk-error';
      }

      if (
        /Unexpected token '<'/.test(message) ||
        /SyntaxError: expected expression, got '<'/.test(message)
      ) {
        event.tags.knownErrorType = '403-error';
      }

      if (/ResizeObserver loop limit exceeded/.test(message)) {
        event.tags.knownErrorType = 'resize-observer-error';
      }

      // Ignore chunk errors if it's the first time they happen
      // If they happen again after a refresh a different error is thrown and that one isn't ignored in sentry
      if (
        (/(Failed to fetch dynamically imported|error loading dynamically imported module|Importing a module script failed)/.test(
          message
        ) ||
          message === 'Load failed') &&
        !/Failed to fetch chunk after reload/.test(message)
      ) {
        event.tags.knownErrorType = 'chunk-error';
      }

      // Following error is known to be caused when user translates
      // a page using his browser
      // It does not affect the user experience so we ignore it
      if (
        /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./.test(
          message
        )
      ) {
        event.tags.knownErrorType = 'translation-react-error';
      }

      if (/Network Error/.test(message)) {
        event.tags.knownErrorType = 'network-error';
      }

      if (/AbortError: AbortError/.test(message)) {
        event.tags.knownErrorType = 'abort-error';
      }

      if (/Request failed with status code 457/.test(message)) {
        event.tags.knownErrorType = '457-error';
      }

      if (error instanceof WithContextError) {
        event.contexts = { ...event.contexts, ...error.context };
      }

      if (
        (axios.isAxiosError(error) && error.response?.status === 401) ||
        error.cause === 401
      ) {
        return null;
      }

      if (
        axios.isAxiosError(error) &&
        error.config?.url &&
        error.config?.method &&
        error.response?.status
      ) {
        event.fingerprint = [
          error.config.url,
          error.config.method,
          error.response.status.toString(),
        ];
      }

      return event;
    },
  });

  if (Capacitor.isNativePlatform()) {
    AppCapacitor.getInfo().then((value) => {
      Sentry.setTag(
        'mobile.app.platform',
        `${Capacitor.getPlatform()}-${value?.version}`
      );
    });
  }
}

let isReloadingChunks = false;

window.addEventListener('vite:preloadError', (event) => {
  const refreshDateString = window.sessionStorage.getItem(
    `retry-vite-chunk-refresh-date`
  );
  const refreshDate = refreshDateString
    ? new Date(refreshDateString)
    : undefined;

  if (!refreshDate || new Date().getTime() - refreshDate.getTime() >= 500) {
    window.sessionStorage.setItem(
      `retry-vite-chunk-refresh-date`,
      new Date().toISOString()
    );
    isReloadingChunks = true;
    window.location.reload();
  } else if (!isReloadingChunks) {
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      `Failed to fetch chunk after reload: ${(event as any).payload}`
    );
  }
});

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'development') {
  const cookies = parseCookies(document.cookie || '');
  const optOutCookie = Boolean(cookies['rgpd-cookie-opt-out'] || false);

  ampli.load({
    client: {
      apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
      configuration: {
        serverZone: 'EU',
        optOut: optOutCookie,
        defaultTracking: true,
        autocapture: {
          sessions: true,
        },
      },
    },
  });

  if (Capacitor.getPlatform() !== 'ios') {
    const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 0.05 });
    ampli.client.add(sessionReplayTracking);
  }
}

setupApiInterceptors();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
